<template>
  <div>
    <ol class="breadcrumb center-items">
      <li><a href="/">Home</a></li>
      <li>
        <a :href="this.link">{{ this.linkTitle }}</a>
      </li>
      <li class="active">{{ this.title }}</li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ["link", "linkTitle", "title"],
};
</script>

<style>
.breadcrumb.center-items {
  display: inline-block;
  margin: 0 auto;
}
.breadcrumb {
  display: inline-block;
  padding: 0;
  margin: 0;
  background: transparent;
  overflow: hidden;
}
.breadcrumb li {
  float: left;
  padding: 8px 15px 8px 33px;
  background: #252932;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  position: relative;
}
.breadcrumb li:first-child {
  background: #27d367;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.breadcrumb li:last-child {
  background: #343840;
  margin-right: 18px;
}
.breadcrumb li:before {
  display: none;
}
.breadcrumb li:after {
  content: "";
  display: block;
  border-left: 18px solid #252932;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  position: absolute;
  top: 0;
  right: -18px;
  z-index: 1;
}
.breadcrumb li:first-child:after {
  border-left-color: #27d367;
}
.breadcrumb li:last-child:after {
  border-left-color: #343840;
}
.breadcrumb li a {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}
@media only screen and (max-width: 479px) {
  .breadcrumb li {
    padding: 8px 15px 8px 30px;
  }
}
</style>