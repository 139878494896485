<template>
  <div>
    <Header class="d-lg-block d-none" />
    <HeaderBack class="d-lg-none d-block" />
    <DappsInformation
      :slug="$route.params.slug"
      :queries="$route.query.ref"
      :pools="$route.query.pool"
    />
    <Footer class="d-lg-block d-none" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import HeaderBack from "@/components/HeaderBack.vue";
import DappsInformation from "@/components/DappsInformation.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "DappsDetail",
  components: {
    Header,
    HeaderBack,
    DappsInformation,
    Footer,
  },
  data() {
    return {
      ip: null,
    };
  },
  created() {
    this.$mixpanel.track("Visit Dapps Detail " + this.$route.params.slug);
  },
};
</script>

<style></style>
