<template>
  <div id="CardTokenPrice" class="item-card-gradual bg-light">
    <!-- Start Mobile -->
    <div class="d-lg-none d-block" v-if="pricingData != null">
      <!-- <div class="head-card"></div> -->
      <div
        class="body-card d-flex justify-content-start gap-3 align-items-center"
      >
        <div class="image-items">
          <picture>
            <source :srcset="pricingData.image?.large" type="image/webp" />
            <img
              class="img-cover rounded-10"
              width="50px"
              height="50px"
              :src="pricingData.image?.thumb"
              alt="image NFT"
            />
          </picture>
        </div>
        <div class="title-card-nft w-100">
          <div class="side-one w-100">
            <h2 class="text-start">{{ pricingData.name }}</h2>
            <div
              class="
                side-other
                d-flex
                justify-content-start
                gap-1
                align-items-center
              "
            >
              <span class="text-small fw-bold text-dark">{{
                Number(pricingData.market_data?.current_price?.usd).toFixed(4)
              }}</span>
              <span
                class="no-sales"
                v-if="pricingData.market_data?.price_change_percentage_24h > 0"
                >+
                {{
                  Number(
                    pricingData.market_data?.price_change_percentage_24h
                  ).toFixed(4)
                }}</span
              >
              <span class="no-sales text-danger" v-else>{{
                Number(
                  pricingData.market_data?.price_change_percentage_24h
                ).toFixed(4)
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-card">
        <div class="starting-bad text-start">
          <h4>Token Scanner</h4>
          <span>Here</span>
        </div>
        <div class="btn-like-click">
          <button class="btn btn-dark rounded-pill">Buy</button>
        </div>
      </div>
    </div>
    <!-- End Mobile -->

    <!-- Start Desktop -->
    <div class="d-lg-block d-none" v-if="pricingData != null">
      <!-- <div class="head-card"></div> -->
      <div
        class="body-card d-flex justify-content-start gap-3 align-items-center"
      >
        <div class="image-items">
          <picture>
            <source :srcset="pricingData.image?.large" type="image/webp" />
            <img
              class="img-cover rounded-10"
              width="50px"
              height="50px"
              :src="pricingData.image?.thumb"
              alt="image NFT"
            />
          </picture>
        </div>
        <div class="title-card-nft w-100">
          <div class="side-one w-100">
            <h2>{{ pricingData.name }}</h2>
            <div
              class="
                side-other
                d-flex
                justify-content-start
                gap-1
                align-items-center
              "
            >
              <span class="text-small fw-bold text-dark"
                >$
                {{
                  Number(pricingData.market_data?.current_price?.usd).toFixed(4)
                }}</span
              >
              <span
                class="no-sales"
                v-if="pricingData.market_data?.price_change_percentage_24h > 0"
                >+
                {{
                  Number(
                    pricingData.market_data?.price_change_percentage_24h
                  ).toFixed(4)
                }}</span
              >
              <span class="no-sales text-danger" v-else>{{
                Number(
                  pricingData.market_data?.price_change_percentage_24h
                ).toFixed(4)
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-card flex-column gap-3">
        <div class="w-100">
          <div class="progress">
            <div
              class="progress-bar bg-success"
              role="progressbar"
              :style="'width: ' + getBars() + '%'"
              :aria-valuenow="pricingData.market_data?.current_price?.usd"
              :aria-valuemin="pricingData.market_data?.low_24h?.usd"
              :aria-valuemax="pricingData.market_data?.high_24h?.usd"
            >
              {{ pricingData.market_data?.current_price?.usd }}
            </div>
          </div>
          <div class="d-flex justify-content-between w-100 mt-2">
            <div>${{ pricingData.market_data?.low_24h?.usd }}</div>
            <div>${{ pricingData.market_data?.high_24h?.usd }}</div>
          </div>
        </div>
        <div class="d-flex justify-content-between w-100">
          <div class="starting-bad">
            <h4>Market Cap Rank</h4>
            <span>{{ pricingData.market_cap_rank }}</span>
          </div>
          <div class="starting-bad">
            <h4 class="text-end">Coingecko Rank</h4>
            <span class="text-end">{{ pricingData.coingecko_rank }}</span>
          </div>
        </div>

        <div class="d-flex justify-content-between w-100">
          <div class="starting-bad">
            <h4>All Time High</h4>
            <div class="d-flex justify-content-start gap-2 align-items-center">
              <div>${{ pricingData.market_data?.ath?.usd }}</div>
              <div
                v-if="pricingData.market_data?.ath_change_percentage?.usd > 0"
                class="no-sales"
              >
                {{
                  Number(
                    pricingData.market_data?.ath_change_percentage?.usd
                  ).toFixed(2)
                }}%
              </div>
              <div v-else class="no-sales text-danger">
                {{
                  Number(
                    pricingData.market_data?.ath_change_percentage?.usd
                  ).toFixed(2)
                }}%
              </div>
            </div>
          </div>
          <div class="starting-bad">
            <h4 class="text-end">All Time Low</h4>
            <div
              class="
                d-flex
                justify-content-start
                gap-2
                align-items-center
                text-end
              "
            >
              <div>${{ pricingData.market_data?.atl?.usd }}</div>
              <div
                v-if="pricingData.market_data?.atl_change_percentage?.usd > 0"
                class="no-sales text-success"
              >
                {{
                  Number(
                    pricingData.market_data?.atl_change_percentage?.usd
                  ).toFixed(2)
                }}%
              </div>
              <div v-else class="no-sales text-danger">
                {{
                  Number(
                    pricingData.market_data?.atl_change_percentage?.usd
                  ).toFixed(2)
                }}%
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between w-100">
          <div class="starting-bad">
            <h4>Market Cap</h4>
            <div>
              {{ Number(pricingData.market_data?.market_cap?.usd) | toUSD }}
            </div>
          </div>
          <div class="starting-bad">
            <h4 class="text-end">Total Value Locked</h4>
            <div class="text-end">
              {{ Number(pricingData.market_data?.total_volume?.usd) | toUSD }}
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between w-100">
          <div class="starting-bad">
            <h4>Trading Volume</h4>
            <div>
              {{
                Number(pricingData.market_data?.fully_diluted_valuation?.usd)
                  | toUSD
              }}
            </div>
          </div>
          <div class="starting-bad">
            <h4 class="text-end">Total Value Locked</h4>
            <div class="text-end">
              {{
                Number(pricingData.market_data?.total_value_locked?.usd) | toUSD
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Desktop -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CardTokenPrice",
  props: ["address", "network", "is_contract"],
  data() {
    return {
      pricingData: null,
    };
  },
  mounted() {
    if (
      this.$props.address !== null &&
      this.$props.address !== "0x" &&
      this.$props.address !== "0xa" &&
      this.$props.address !== "0xb" &&
      this.$props.address !== "0xc" &&
      this.$props.is_contract !== "false"
    ) {
      this.getDatas();
    }
  },
  methods: {
    getDatas() {
      let networks;
      if (this.$props.network === "BSC") {
        networks = "binance-smart-chain";
      } else if (this.$props.network === "Ethereum") {
        networks = "ethereum";
      } else {
        networks = "binance-smart-chain";
      }
      axios
        .get(
          "https://api-data.dinodapps.com/api/v3/coins/" +
            networks +
            "/contract/" +
            this.$props.address
        )
        .then((res) => {
          this.pricingData = res.data;
          // console.log(res.data);
        });
    },
    getBars() {
      let low = this.pricingData.market_data?.low_24h?.usd;
      let high = this.pricingData.market_data?.high_24h?.usd;
      let price = this.pricingData.market_data?.current_price?.usd;

      let widthProgress = ((price - low) / low / ((high - low) / low)) * 100;

      return widthProgress;
    },
  },
};
</script>