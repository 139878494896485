<template>
  <div id="DappsInformation">
    <div v-if="queries != undefined && isTrue === 0" class="container py-5">
      <h5 class="text-white">Anti Bot Validation</h5>
      <VueRecaptcha
        sitekey="6LcDil8jAAAAAGF4cC8qoIrcSbdNkbziDwoQuthr"
        :load-recaptcha-script="true"
        @verify="onVerify"
        @expired="onExpired"
        ref="recaptcha"
      ></VueRecaptcha>
    </div>
    <div v-else>
      <section class="un-details-collectibles d-lg-none d-block">
        <!-- head -->
        <div class="head">
          <div class="container" style="padding: 0 19px">
            <section class="unSwiper-cards">
              <!-- un-title-default -->
              <swiper :options="swiperOptionNft" ref="swiperTop" class="swiper">
                <swiper-slide v-show="dappsDetail.banner_one_url != null">
                  <div class="item-card-gradual">
                    <a
                      href="#"
                      class="body-card p-0"
                      @click="openLightboxOnSlide(1)"
                    >
                      <div class="cover-nft">
                        <picture>
                          <source
                            :srcset="
                              !dappsDetail.banner_one_url?.startsWith('/images')
                                ? 'https://api-dapps.dinowallet.org/images/ss-dapps/' +
                                  dappsDetail.banner_one_url
                                : dappsDetail.banner_one_url
                            "
                            @error="
                              () =>
                                (dappsDetail.banner_one_url =
                                  '/images/banner/banner-empty.png')
                            "
                            width="100%"
                            height="22vh"
                            style="
                              width: 100% !important;
                              height: 23vh !important;
                            "
                            type="image/webp"
                          />
                          <img
                            class="img-cover"
                            :src="
                              !dappsDetail.banner_one_url?.startsWith('/images')
                                ? 'https://api-dapps.dinowallet.org/images/ss-dapps/' +
                                  dappsDetail.banner_one_url
                                : dappsDetail.banner_one_url
                            "
                            @error="
                              () =>
                                (dappsDetail.banner_one_url =
                                  '/images/banner/banner-empty.png')
                            "
                            width="100%"
                            height="22vh"
                            style="
                              width: 100% !important;
                              height: 23vh !important;
                            "
                            alt="image NFT"
                          />
                        </picture>
                        <div class="countdown-time">
                          <span>{{ dappsDetail.banner_one_text }}</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </swiper-slide>
                <swiper-slide v-show="dappsDetail.banner_two_url != null">
                  <div class="item-card-gradual">
                    <a
                      href="#"
                      class="body-card p-0"
                      @click="openLightboxOnSlide(1)"
                    >
                      <div class="cover-nft">
                        <picture>
                          <source
                            :srcset="
                              !dappsDetail.banner_two_url?.startsWith('/images')
                                ? 'https://api-dapps.dinowallet.org/images/ss-dapps/' +
                                  dappsDetail.banner_two_url
                                : dappsDetail.banner_two_url
                            "
                            @error="
                              () =>
                                (dappsDetail.banner_two_url =
                                  '/images/banner/banner-empty.png')
                            "
                            type="image/webp"
                            width="100%"
                            height="22vh"
                            style="
                              width: 100% !important;
                              height: 23vh !important;
                            "
                          />
                          <img
                            class="img-cover"
                            :src="
                              !dappsDetail.banner_two_url?.startsWith('/images')
                                ? 'https://api-dapps.dinowallet.org/images/ss-dapps/' +
                                  dappsDetail.banner_two_url
                                : dappsDetail.banner_two_url
                            "
                            @error="
                              () =>
                                (dappsDetail.banner_two_url =
                                  '/images/banner/banner-empty.png')
                            "
                            width="100%"
                            height="22vh"
                            style="
                              width: 100% !important;
                              height: 23vh !important;
                            "
                            alt="image NFT"
                          />
                        </picture>
                        <div class="countdown-time">
                          <span>{{ dappsDetail.banner_two_text }}</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </swiper-slide>
                <swiper-slide v-show="dappsDetail.banner_three_url != null">
                  <div class="item-card-gradual">
                    <a
                      href="#"
                      class="body-card p-0"
                      @click="openLightboxOnSlide(1)"
                    >
                      <div class="cover-nft">
                        <picture>
                          <source
                            :srcset="
                              !dappsDetail.banner_three_url?.startsWith(
                                '/images'
                              )
                                ? 'https://api-dapps.dinowallet.org/images/ss-dapps/' +
                                  dappsDetail.banner_three_url
                                : dappsDetail.banner_three_url
                            "
                            @error="
                              () =>
                                (dappsDetail.banner_three_url =
                                  '/images/banner/banner-empty.png')
                            "
                            width="100%"
                            height="22vh"
                            style="
                              width: 100% !important;
                              height: 23vh !important;
                            "
                            type="image/webp"
                          />
                          <img
                            class="img-cover"
                            :src="
                              !dappsDetail.banner_three_url?.startsWith(
                                '/images'
                              )
                                ? 'https://api-dapps.dinowallet.org/images/ss-dapps/' +
                                  dappsDetail.banner_three_url
                                : dappsDetail.banner_three_url
                            "
                            @error="
                              () =>
                                (dappsDetail.banner_three_url =
                                  '/images/banner/banner-empty.png')
                            "
                            width="100%"
                            height="22vh"
                            style="
                              width: 100% !important;
                              height: 23vh !important;
                            "
                            alt="image NFT"
                          />
                        </picture>
                        <div class="countdown-time">
                          <span>{{ dappsDetail.banner_three_text }}</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </swiper-slide>
                <div
                  class="swiper-button-next swiper-button-white"
                  slot="button-next"
                ></div>
                <div
                  class="swiper-button-prev swiper-button-white"
                  slot="button-prev"
                ></div>
              </swiper>
            </section>
          </div>

          <FsLightbox
            :toggler="toggler"
            :slide="slide"
            :sources="[
              dappsDetail.banner_one_url,
              dappsDetail.banner_two_url,
              dappsDetail.banner_three_url,
            ]"
          />
          <div
            class="body custom pb-0 d-flex flex-row flex-nowrap align-items-center justify-content-start gap-2 overflow-auto w-100"
          >
            <span
              class="btn-xs-size bg-pink text-white text-nowrap w-full rounded-pill"
              style="min-width: max-content !important"
              v-for="(category, index) in dappsDetail.categorys"
              :key="index"
            >
              {{ category.text }}
            </span>
          </div>

          <div
            class="title-card-text d-flex align-items-center justify-content-start gap-3"
          >
            <div class="image-items">
              <picture>
                <source
                  :srcset="
                    !dappsDetail.icon_url?.startsWith('/images')
                      ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                        dappsDetail.icon_url
                      : dappsDetail.icon_url
                  "
                  type="image/webp"
                  @error="
                    () => (dappsDetail.icon_url = '/images/no-images.png')
                  "
                />
                <img
                  class="user-img rounded-10"
                  width="50px"
                  height="50px"
                  :src="
                    !dappsDetail.icon_url?.startsWith('/images')
                      ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                        dappsDetail.icon_url
                      : dappsDetail.icon_url
                  "
                  @error="
                    () => (dappsDetail.icon_url = '/images/no-images.png')
                  "
                  alt=""
                />
              </picture>
            </div>
            <div class="text">
              <h1>{{ dappsDetail.name }}</h1>
              <p class="text-wrap">{{ dappsDetail.tagline }}</p>
            </div>
          </div>

          <div class="txt-price-coundown">
            <a :href="adsDapps?.link" v-if="adsDapps !== []" class="img-fluid">
              <img
                :src="
                  !adsDapps?.photo?.startsWith('/images')
                    ? imagesUrl + 'images/ads/' + adsDapps?.photo
                    : adsDapps?.photo
                "
                class="img-fluid"
                alt=""
              />
            </a>
            <img
              src="/images/ads/ads-no-banner-mobile-dark.png"
              alt=""
              v-else
            />
            <div class="text-end">
              <router-link
                to="/advertise"
                class="text-end btn btn-outline-secondary btn-xs-size text-dark text-decoration-none"
              >
                Ads Here
              </router-link>
            </div>
          </div>

          <div class="txt-price-coundown d-flex justify-content-between">
            <div class="price">
              <h2>Supported Network</h2>
              <span
                class="text-dark d-flex flex-wrap justify-content-start align-items-center gap-2"
              >
                <span
                  v-for="(network, index) in dappsDetail.networks"
                  :key="index"
                  class="d-inline-block"
                >
                  <img
                    :src="network.logo_url"
                    alt=""
                    v-tooltip.bottom="network.text"
                    width="24"
                  />
                </span>
              </span>
            </div>
            <div class="coundown ms-5 ms-lg-0">
              <h3 class="text-nowrap">Submitted At</h3>
              <h6 class="text-nowrap">{{ dappsDetail.submitted_at }}</h6>
            </div>
          </div>
          <div
            class="main-search-header style-border"
            v-show="dappsDetail.token_contract != null"
          >
            <ul
              class="nav nav-pills nav-tab-search no-wrap ps-2 pl-4 justify-content-start"
              id="contractTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  :class="['nav-link', { active: selected === 'All' }]"
                  @click="selected = 'All'"
                  id="pills-all-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-all"
                  type="button"
                  role="tab"
                  aria-controls="pills-all"
                  aria-selected="true"
                >
                  All
                </button>
              </li>
              <li
                class="nav-item"
                role="presentation"
                v-for="(net, index) in dappsDetail.token_contract"
                :key="index"
              >
                <button
                  :class="[
                    'nav-link text-nowrap',
                    { active: selected === net.network },
                  ]"
                  @click="selected = net.network"
                  :id="'pills-' + net.network + '-tab'"
                  data-bs-toggle="pill"
                  :data-bs-target="'#pills-' + net.network"
                  type="button"
                  role="tab"
                  :aria-controls="'pills-' + net.network"
                  aria-selected="true"
                >
                  <span class="text-nowrap">
                    {{ net.network }}
                  </span>
                </button>
              </li>
            </ul>
          </div>
          <div
            class="tab-content"
            id="contractTabContent"
            v-show="dappsDetail.token_contract != null"
          >
            <div
              :class="[
                'tab-pane fade',
                { show: selected === 'All' },
                { active: selected === 'All' },
              ]"
              id="#pills-all"
              role="tabpanel"
              aria-labelledby="pills-all-tab"
            >
              <div class="unSwiper-cards bg-white py-3">
                <swiper
                  :options="swiperOptionNft"
                  ref="swiperTop"
                  class="swiper cardNft"
                >
                  <swiper-slide
                    v-for="(listContract, index) in gets"
                    :key="index"
                  >
                    <div class="w-100 un-navMenu-default">
                      <CardTokenPrice
                        :address="listContract.contract"
                        :network="listContract.network"
                      />
                    </div>
                  </swiper-slide>
                  <div class="swiper-button-next"></div>
                  <div class="swiper-button-prev"></div>
                </swiper>
              </div>

              <div class="un-navMenu-default without-visit py-3 bg-white">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#mdllContract"
                      class="nav-link visited"
                    >
                      <div class="item-content-link">
                        <div class="icon bg-blue-1 color-secondary">
                          <i class="ri-file-list-3-line"></i>
                        </div>
                        <h3 class="link-title">Smart Contract</h3>
                      </div>
                      <div class="other-cc">
                        <span class="badge-text"></span>
                        <div class="icon-arrow">
                          <i class="ri-arrow-drop-right-line"></i>
                        </div>
                      </div>
                    </a>
                    <div
                      class="modal transition-bottom screenFull defaultModal mdlladd__rate fade"
                      id="mdllContract"
                      tabindex="-1"
                      aria-labelledby="mdllContract"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <div class="item-shared">
                              <div class="image-items">
                                <picture>
                                  <source
                                    :srcset="dappsDetail.icon_url"
                                    type="image/webp"
                                  />
                                  <img
                                    class="user-img"
                                    :src="dappsDetail.icon_url"
                                    alt=""
                                  />
                                </picture>
                              </div>
                              <div class="txt">
                                <h1>{{ dappsDetail.name }}</h1>
                                <p v-if="dappsDetail.tagline != null">
                                  {{ dappsDetail.tagline }}
                                </p>
                                <p v-else>No tagline</p>
                              </div>
                            </div>
                            <button
                              type="button"
                              class="btn btnClose"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <i class="ri-close-fill"></i>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="content-share-socials">
                              <div
                                class="un-navMenu-default p-0"
                                v-for="(
                                  net, index
                                ) in dappsDetail.token_contract"
                                :key="index"
                              >
                                <ul
                                  class="nav flex-column"
                                  v-for="(listContract, index) in net.contracts"
                                  :key="index"
                                >
                                  <li class="nav-item">
                                    <button
                                      type="button"
                                      class="btn nav-link bg-snow"
                                    >
                                      <div class="item-content-link">
                                        <h3
                                          class="link-title"
                                          v-if="listContract.contract !== null"
                                        >
                                          {{
                                            listContract.contract
                                              | truncate(16, "...", 50)
                                          }}
                                        </h3>
                                        <h3 class="link-title" v-else>
                                          {{ listContract.contract }}
                                        </h3>
                                      </div>
                                      <div class="other-cc">
                                        <span class="badge-text"></span>
                                        <div class="icon-arrow">
                                          <i class="ri-file-copy-2-line"></i>
                                        </div>
                                      </div>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer border-0">
                            <div class="env-pb"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="mt-2 px-4">
                <div class="d-flex justify-content-center flex-column">
                  <div class="text-end">
                    <router-link
                      to="/advertise"
                      class="text-end btn btn-outline-secondary btn-xs-size text-dark text-decoration-none"
                    >
                      Ads Here
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(net, index) in dappsDetail.token_contract"
              :key="index"
              :class="[
                'tab-pane fade',
                { show: selected === net.network },
                { active: selected === net.network },
              ]"
              :id="'#pills-' + net.network"
              role="tabpanel"
              :aria-labelledby="'pills-' + net.network + '-tab'"
            >
              <div class="unSwiper-cards bg-white py-3">
                <swiper
                  :options="swiperOptionNft"
                  ref="swiperTop"
                  class="swiper cardNft"
                >
                  <swiper-slide
                    v-for="(listContract, index) in net.contracts"
                    :key="index"
                  >
                    <div class="w-100 un-navMenu-default">
                      <CardTokenPrice
                        :address="listContract.contract"
                        :network="net.network"
                      />
                    </div>
                  </swiper-slide>
                  <div class="swiper-button-next"></div>
                  <div class="swiper-button-prev"></div>
                </swiper>
              </div>

              <div class="un-navMenu-default without-visit py-3 bg-white">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      :data-bs-target="'#mdllContract' + net.network"
                      class="nav-link visited"
                    >
                      <div class="item-content-link">
                        <div class="icon bg-blue-1 color-blue">
                          <i class="ri-file-list-3-line"></i>
                        </div>
                        <h3 class="link-title">Smart Contract</h3>
                      </div>
                      <div class="other-cc">
                        <span class="badge-text"></span>
                        <div class="icon-arrow">
                          <i class="ri-arrow-drop-right-line"></i>
                        </div>
                      </div>
                    </a>
                    <div
                      class="modal transition-bottom screenFull defaultModal mdlladd__rate fade"
                      :id="'mdllContract' + net.network"
                      tabindex="-1"
                      :aria-labelledby="'mdllContract' + net.network"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <div class="item-shared">
                              <div class="image-items">
                                <picture>
                                  <source
                                    :srcset="dappsDetail.icon_url"
                                    type="image/webp"
                                  />
                                  <img
                                    class="user-img"
                                    :src="dappsDetail.icon_url"
                                    alt=""
                                  />
                                </picture>
                              </div>
                              <div class="txt">
                                <h1>{{ dappsDetail.name }}</h1>
                                <p v-if="dappsDetail.tagline != null">
                                  {{ dappsDetail.tagline }}
                                </p>
                                <p v-else>No tagline</p>
                              </div>
                            </div>
                            <button
                              type="button"
                              class="btn btnClose"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <i class="ri-close-fill"></i>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="content-share-socials">
                              <h6 class="text-sm">
                                Smart Contract on {{ net.network }}
                              </h6>
                              <div class="un-navMenu-default p-0">
                                <ul
                                  class="nav flex-column"
                                  v-for="(listContract, index) in net.contracts"
                                  :key="index"
                                >
                                  <li class="nav-item">
                                    <button
                                      type="button"
                                      class="btn nav-link bg-snow"
                                    >
                                      <div class="item-content-link">
                                        <h3
                                          class="link-title text-truncate"
                                          v-if="listContract.contract !== null"
                                        >
                                          {{
                                            listContract.contract
                                              | truncate(6, "...", 50)
                                          }}
                                        </h3>
                                        <h3
                                          class="link-title text-truncate"
                                          v-else
                                        >
                                          {{ listContract.contract }}
                                        </h3>
                                      </div>
                                      <div class="other-cc">
                                        <span class="badge-text"></span>
                                        <div class="icon-arrow">
                                          <i class="ri-file-copy-2-line"></i>
                                        </div>
                                      </div>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer border-0">
                            <div class="env-pb"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- body -->
        <div class="body">
          <ul
            class="nav nav-pills nav-pilled-tab w-100"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <button
                class="nav-link w-100 active"
                id="pills-Info-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Info"
                type="button"
                role="tab"
                aria-controls="pills-Info"
                aria-selected="true"
              >
                Info
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link w-100"
                id="pills-Owner-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Owner"
                type="button"
                role="tab"
                aria-controls="pills-Owner"
                aria-selected="false"
              >
                Dapps&nbsp;Link
              </button>
            </li>
          </ul>

          <div class="tab-content content-custome-data" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-Info"
              role="tabpanel"
              aria-labelledby="pills-Info-tab"
            >
              <div class="description py-3">
                <h6 class="fw-bold">Dapps Introduction</h6>
                <p>
                  {{ dappsDetail.description }}
                </p>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-Owner"
              role="tabpanel"
              aria-labelledby="pills-Owner-tab"
            >
              <div class="content-share-socials mt-3">
                <div class="un-navMenu-default p-0">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <a
                        class="nav-link py-2 px-3 facebook"
                        :href="dappsDetail.facebook_url"
                      >
                        <div
                          class="d-flex item-content-link align-items-center"
                        >
                          <h3 class="icon-svg mb-0">
                            <em class="ri-facebook-circle-fill text-muted"></em>
                          </h3>
                          <h3 class="link-title ms-2">Facebook</h3>
                        </div>
                        <div class="other-cc">
                          <span class="badge-text"></span>
                          <div class="icon-arrow">
                            <i class="ri-arrow-drop-right-line"></i>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link py-2 px-3 twitter"
                        :href="dappsDetail.twitter_url"
                      >
                        <div class="item-content-link">
                          <h3 class="icon-svg mb-0">
                            <em class="ri-twitter-fill text-muted"></em>
                          </h3>
                          <h3 class="link-title ms-2">Twitter</h3>
                        </div>
                        <div class="other-cc">
                          <span class="badge-text"></span>
                          <div class="icon-arrow">
                            <i class="ri-arrow-drop-right-line"></i>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link py-2 px-3 instrgrame"
                        :href="dappsDetail.instagram_url"
                      >
                        <div class="item-content-link">
                          <h3 class="icon-svg mb-0">
                            <em class="ri-instagram-fill text-muted"></em>
                          </h3>
                          <h3 class="link-title ms-2">Instagram</h3>
                        </div>
                        <div class="other-cc">
                          <span class="badge-text"></span>
                          <div class="icon-arrow">
                            <i class="ri-arrow-drop-right-line"></i>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link py-2 px-3 telegram"
                        :href="dappsDetail.telegram_url"
                      >
                        <div class="item-content-link">
                          <h3 class="icon-svg mb-0">
                            <em class="ri-telegram-fill text-muted"></em>
                          </h3>
                          <h3 class="link-title ms-2">Telegram</h3>
                        </div>
                        <div class="other-cc">
                          <span class="badge-text"></span>
                          <div class="icon-arrow">
                            <i class="ri-arrow-drop-right-line"></i>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <h5 class="fw-bold">Related dApps</h5>
          <div class="row mb-4">
            <div
              class="col-lg-3 col-md-4 col-xs-12 col-sm-12"
              v-for="(related, index) in relatedDapps"
              :key="index"
            >
              <a
                :href="'/dapps-detail/' + related.slug"
                class="card bg-light mt-3 rounded-10 text-decoration-none text-dark"
              >
                <div class="card-body">
                  <div class="d-flex justify-content-start gap-3">
                    <img
                      class="rounded-10"
                      v-if="related.icon_url"
                      width="50px"
                      height="50px"
                      :src="related.icon_url"
                      @error="() => (related.icon_url = null)"
                      alt=""
                    />
                    <img
                      class="rounded-10"
                      width="50px"
                      height="50px"
                      v-else
                      src="/images/no-images.png"
                      alt=""
                    />
                    <div class="d-flex flex-column">
                      <h6 class="fw-bold">
                        {{ related.name | truncateFirst(12, "...") }}
                      </h6>
                      <span
                        class="text-dark d-flex flex-wrap justify-content-start align-items-center gap-2"
                      >
                        <span
                          v-for="(network, index) in related.networks"
                          :key="index"
                          class="d-inline-block"
                        >
                          <img
                            :src="network.logo_url"
                            width="20"
                            height="20"
                            alt=""
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <!-- footer -->
        <div class="space-sticky-footer"></div>
        <div class="footer">
          <div class="content">
            <div class="links-item-pages">
              <a href="#" @click="$router.go(-1)" class="icon-box prev">
                <i class="ri-arrow-left-line"></i>
              </a>
            </div>
            <a
              @click="handleCount"
              class="btn btn-bid-items"
              v-if="this.queries !== undefined"
            >
              <p>Visit dApps</p>

              <div class="ico">
                <i class="ri-arrow-drop-right-line"></i>
              </div>
            </a>
            <a :href="dappsDetail.dapps_url" class="btn btn-bid-items" v-else
              ><p>Visit dApps</p>

              <div class="ico">
                <i class="ri-arrow-drop-right-line"></i>
              </div>
            </a>
          </div>
        </div>
      </section>

      <section class="bg-light d-none d-lg-block">
        <div class="container py-4">
          <Breadcrumb
            link="https://dinodapps.com/search"
            linkTitle="Dapps Search"
            :title="dappsDetail.name"
          />
          <div class="bg-white p-4 rounded-10 mt-3">
            <div class="d-flex justify-content-between">
              <div class="col-8">
                <div class="d-flex justify-content-start gap-4">
                  <div class="d-flex flex-column">
                    <img
                      class="rounded-circle"
                      width="150px"
                      height="150px"
                      :src="
                        !dappsDetail.icon_url?.startsWith('/images')
                          ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                            dappsDetail.icon_url
                          : dappsDetail.icon_url
                      "
                      @error="
                        () => (dappsDetail.icon_url = '/images/no-images.png')
                      "
                      alt=""
                    />
                  </div>
                  <div>
                    <h3>{{ dappsDetail.name }}</h3>
                    <p class="text-secondary">{{ dappsDetail.tagline }}</p>
                    <div class="d-flex justify-content-start gap-2">
                      <span
                        class="btn-xs-size bg-light text-dark text-nowrap w-full rounded-3 border"
                        style="min-width: max-content !important"
                        v-for="(category, index) in dappsDetail.categorys"
                        :key="index"
                      >
                        {{ category.text }}
                      </span>
                    </div>
                    <div class="d-flex justify-content-start gap-3 mt-3">
                      <span
                        v-for="(network, index) in dappsDetail.networks"
                        :key="index"
                        class="d-inline-block"
                      >
                        <img
                          :src="network.logo_url"
                          alt=""
                          v-tooltip.bottom="network.text"
                          width="24"
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="d-flex justify-content-start gap-4 align-items-center my-3"
                >
                  <a
                    @click="handleCount"
                    target="_blank"
                    class="btn btn-white rounded-10 btn-dapps fw-bold"
                    v-if="this.queries !== undefined"
                  >
                    Open Dapps
                  </a>
                  <a
                    :href="dappsDetail.dapps_url"
                    target="_blank"
                    class="btn btn-white rounded-10 btn-dapps fw-bold"
                    v-else
                  >
                    Open Dapps
                  </a>

                  <div class="d-flex justify-content-start gap-2">
                    <a
                      class="text-decoration-none btn-social"
                      :href="dappsDetail.facebook_url"
                      v-if="dappsDetail.facebook_url != null"
                    >
                      <em
                        class="h5 mb-0 ri-facebook-circle-line text-muted"
                      ></em>
                    </a>
                    <a
                      class="text-decoration-none btn-social"
                      :href="dappsDetail.twitter_url"
                      v-if="dappsDetail.twitter_url != null"
                    >
                      <em class="h5 mb-0 ri-twitter-line text-muted"></em>
                    </a>
                    <a
                      class="text-decoration-none btn-social"
                      :href="dappsDetail.github_url"
                      v-if="dappsDetail.github_url != null"
                    >
                      <em class="h5 mb-0 ri-github-line text-muted"></em>
                    </a>
                    <a
                      class="text-decoration-none btn-social"
                      :href="dappsDetail.instagram_url"
                      v-if="dappsDetail.instagram_url != null"
                    >
                      <em class="h5 mb-0 ri-instagram-line text-muted"></em>
                    </a>
                    <a
                      class="text-decoration-none btn-social"
                      :href="dappsDetail.youtube_url"
                      v-if="dappsDetail.youtube_url != null"
                    >
                      <em class="h5 mb-0 ri-youtube-line text-muted"></em>
                    </a>
                    <a
                      class="text-decoration-none btn-social"
                      :href="dappsDetail.discord_url"
                      v-if="dappsDetail.discord_url != null"
                    >
                      <em class="h5 mb-0 ri-discord-line text-muted"></em>
                    </a>
                    <a
                      class="text-decoration-none btn-social"
                      :href="dappsDetail.medium_url"
                      v-if="dappsDetail.medium_url != null"
                    >
                      <em class="h5 mb-0 ri-medium-line text-muted"></em>
                    </a>
                    <a
                      class="text-decoration-none btn-social"
                      :href="dappsDetail.reddit_url"
                      v-if="dappsDetail.reddit_url != null"
                    >
                      <em class="h5 mb-0 ri-reddit-line text-muted"></em>
                    </a>
                    <!-- cg -->
                    <!-- cmc -->
                    <!-- audit -->
                  </div>
                </div>
              </div>

              <div class="col-4">
                <section class="unSwiper-cards">
                  <swiper
                    :options="swiperOption"
                    ref="swiperTop"
                    class="swiper gallery-top"
                  >
                    <swiper-slide v-show="dappsDetail.banner_one_url != null">
                      <a href="#" @click="openLightboxOnSlide(1)">
                        <img
                          :src="
                            !dappsDetail.banner_one_url?.startsWith('/images')
                              ? 'https://api-dapps.dinowallet.org/images/ss-dapps/' +
                                dappsDetail.banner_one_url
                              : dappsDetail.banner_one_url
                          "
                          @error="
                            () =>
                              (dappsDetail.banner_one_url =
                                '/images/banner/banner-empty.png')
                          "
                          width="410px"
                          class="rounded-10 img-fluid"
                          alt=""
                        />
                      </a>
                    </swiper-slide>
                    <swiper-slide v-show="dappsDetail.banner_two_url != null">
                      <a href="#" @click="openLightboxOnSlide(2)">
                        <img
                          :src="
                            !dappsDetail.banner_two_url?.startsWith('/images')
                              ? 'https://api-dapps.dinowallet.org/images/ss-dapps/' +
                                dappsDetail.banner_two_url
                              : dappsDetail.banner_two_url
                          "
                          @error="
                            () =>
                              (dappsDetail.banner_two_url =
                                '/images/banner/banner-empty.png')
                          "
                          width="410px"
                          class="rounded-10 img-fluid"
                          alt=""
                        />
                      </a>
                    </swiper-slide>
                    <swiper-slide v-show="dappsDetail.banner_three_url != null">
                      <a href="#" @click="openLightboxOnSlide(3)">
                        <img
                          :src="
                            !dappsDetail.banner_three_url?.startsWith('/images')
                              ? 'https://api-dapps.dinowallet.org/images/ss-dapps/' +
                                dappsDetail.banner_three_url
                              : dappsDetail.banner_three_url
                          "
                          @error="
                            () =>
                              (dappsDetail.banner_three_url =
                                '/images/banner/banner-empty.png')
                          "
                          width="410px"
                          class="rounded-10 img-fluid"
                          alt=""
                        />
                      </a>
                    </swiper-slide>
                    <div
                      class="swiper-button-next swiper-button-white"
                      slot="button-next"
                    ></div>
                    <div
                      class="swiper-button-prev swiper-button-white"
                      slot="button-prev"
                    ></div>
                  </swiper>

                  <swiper
                    :options="swiperOptionThumbs"
                    class="swiper gallery-thumbs mt-2"
                    ref="swiperThumbs"
                  >
                    <swiper-slide>
                      <img
                        style="height: 25%"
                        width="95%"
                        class="rounded-8"
                        :src="
                          !dappsDetail.banner_one_url?.startsWith('/images')
                            ? 'https://api-dapps.dinowallet.org/images/ss-dapps/' +
                              dappsDetail.banner_one_url
                            : dappsDetail.banner_one_url
                        "
                        alt=""
                      />
                    </swiper-slide>
                    <swiper-slide>
                      <img
                        style="height: 25%"
                        width="95%"
                        class="rounded-8"
                        :src="
                          !dappsDetail.banner_two_url?.startsWith('/images')
                            ? 'https://api-dapps.dinowallet.org/images/ss-dapps/' +
                              dappsDetail.banner_two_url
                            : dappsDetail.banner_two_url
                        "
                        alt=""
                      />
                    </swiper-slide>
                    <swiper-slide>
                      <img
                        style="height: 25%"
                        width="95%"
                        class="rounded-8"
                        :src="
                          !dappsDetail.banner_three_url?.startsWith('/images')
                            ? 'https://api-dapps.dinowallet.org/images/ss-dapps/' +
                              dappsDetail.banner_three_url
                            : dappsDetail.banner_three_url
                        "
                        alt=""
                      />
                    </swiper-slide>
                  </swiper>

                  <FsLightbox
                    :toggler="toggler"
                    :slide="slide"
                    :sources="[
                      dappsDetail.banner_one_url,
                      dappsDetail.banner_two_url,
                      dappsDetail.banner_three_url,
                    ]"
                  />
                </section>
              </div>
            </div>

            <div class="d-flex flex-column border-top border-secondary mt-4">
              <div class="mt-4">
                <h5 class="fw-bold">Project Description</h5>
                <p class="text-secondary">{{ dappsDetail.description }}</p>
              </div>

              <div class="mt-4">
                <h5 class="fw-bold">{{ dappsDetail.name }} Dashboards</h5>
                <div class="col-12 row">
                  <div
                    class="col-6 mt-2"
                    v-for="(listContract, index) in gets"
                    :key="index"
                  >
                    <CardTokenPrice
                      :address="listContract.contract"
                      :network="listContract.network"
                      :is_contract="listContract.is_token_contract"
                    />
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <div class="d-flex justify-content-center flex-column">
                  <a
                    :href="adsDapps?.link"
                    v-if="adsDapps !== []"
                    class="img-fluid"
                  >
                    <img
                      :src="
                        !adsDapps?.photo?.startsWith('/images')
                          ? imagesUrl + 'images/ads/' + adsDapps?.photo
                          : adsDapps?.photo
                      "
                      class="img-fluid"
                      alt=""
                      v-if="adsDapps !== null"
                    />
                  </a>
                  <img src="/images/ads/ads-no-banner-dark.png" alt="" v-else />
                  <div class="text-end">
                    <router-link
                      to="/advertise"
                      class="text-end btn btn-outline-secondary btn-xs-size text-dark text-decoration-none"
                    >
                      Ads Here
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="mt-5">
                <h5 class="fw-bold">Token Contract</h5>
                <div
                  class="d-flex justify-content-start gap-1 align-items-center"
                >
                  <span class="text-secondary">Smart Contract list</span>
                  <a href="" class="fw-bold text-dark text-decoration-none"
                    >{{ gets.length }} {{ dappsDetail.name }} Smart Contract
                    <em class="ri ri-arrow-right-line"></em
                  ></a>
                </div>
              </div>

              <div class="mt-5 w-full">
                <h5 class="fw-bold">Social Tracking</h5>
                <div class="col-12 row">
                  <div class="col-6" v-show="is_twitter_active">
                    <div class="card mt-3">
                      <div class="card-body">
                        <apexchart
                          type="line"
                          ref="twitters"
                          height="350"
                          :options="chartOptions"
                          :series="series"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" v-show="is_telegram_active">
                    <div class="card mt-3">
                      <div class="card-body">
                        <apexchart
                          type="line"
                          ref="telegrams"
                          height="350"
                          :options="chartOptionsTelegram"
                          :series="seriesTelegram"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" v-show="is_github_active">
                    <div class="card mt-3">
                      <div class="card-body">
                        <apexchart
                          type="line"
                          ref="githubs"
                          height="350"
                          :options="chartOptionsGithub"
                          :series="seriesGithub"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 class="fw-bold mt-4">Related dApps</h5>
          <div class="row">
            <div
              class="col-3"
              v-for="(related, index) in relatedDapps"
              :key="index"
            >
              <a
                :href="'/dapps-detail/' + related.slug"
                class="card bg-white rounded-10 text-decoration-none text-dark"
              >
                <div class="card-body">
                  <div class="d-flex justify-content-start gap-3">
                    <img
                      class="rounded-10"
                      v-if="related.icon_url"
                      width="50px"
                      height="50px"
                      :src="related.icon_url"
                      @error="() => (related.icon_url = null)"
                      alt=""
                    />
                    <img
                      class="rounded-10"
                      width="50px"
                      height="50px"
                      v-else
                      src="/images/no-images.png"
                      alt=""
                    />
                    <div class="d-flex flex-column">
                      <h6 class="fw-bold">
                        {{ related.name | truncateFirst(12, "...") }}
                      </h6>
                      <span
                        class="text-dark d-flex flex-wrap justify-content-start align-items-center gap-2"
                      >
                        <span
                          v-for="(network, index) in related.networks"
                          :key="index"
                          class="d-inline-block"
                        >
                          <img
                            :src="network.logo_url"
                            width="20"
                            height="20"
                            alt=""
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FsLightbox from "fslightbox-vue";
import CardTokenPrice from "./CardTokenPrice.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import VueApexCharts from "vue-apexcharts";
import Breadcrumb from "./Breadcrumb.vue";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "DappsInformation",
  props: ["slug", "queries", "pools"],
  components: {
    FsLightbox,
    CardTokenPrice,
    Swiper,
    SwiperSlide,
    apexchart: VueApexCharts,
    Breadcrumb,
    VueRecaptcha,
  },
  data() {
    return {
      slugs: null,
      dappsDetail: [],
      relatedDapps: [],
      is_github_active: Boolean,
      is_twitter_active: Boolean,
      is_telegram_active: Boolean,
      toggler: false,
      slide: 1,
      isInit: 0,
      selected: "All",
      swiperOptionNft: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 10,
        centeredSlides: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 10,
        loopedSlides: 3,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 3,
        loopedSlides: 3,
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },

      series: [
        {
          name: "Followers",
          data: [],
        },
      ],
      chartOptions: {
        theme: {
          mode: "dark",
        },
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          background: "rgba(0, 0, 0, 0)",
          foreColor: "#fff",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 1000,
            },
          },
          dropShadow: {
            enabled: true,
            opacity: 0.3,
            blur: 5,
            left: -7,
            top: 22,
          },
          events: {
            animationEnd: function (chartCtx) {
              const newData1 = chartCtx.w.config.series[0].data.slice();
              newData1.shift();
              window.setTimeout(function () {
                chartCtx.updateOptions(
                  {
                    series: [
                      {
                        data: newData1,
                      },
                    ],
                    subtitle: {
                      text: [],
                    },
                  },
                  false,
                  false
                );
              }, 300);
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Twitter Traffic",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#14151C", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
          borderColor: "#40475D",
        },
        xaxis: {
          categories: [],
          max: 10,
        },
      },

      seriesTelegram: [
        {
          name: "Member",
          data: [],
        },
      ],
      chartOptionsTelegram: {
        theme: {
          mode: "dark",
        },
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          background: "rgba(0, 0, 0, 0)",
          foreColor: "#fff",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 1000,
            },
          },
          dropShadow: {
            enabled: true,
            opacity: 0.3,
            blur: 5,
            left: -7,
            top: 22,
          },
          events: {
            animationEnd: function (chartCtx) {
              const newData1 = chartCtx.w.config.series[0].data.slice();
              newData1.shift();
              window.setTimeout(function () {
                chartCtx.updateOptions(
                  {
                    series: [
                      {
                        data: newData1,
                      },
                    ],
                    subtitle: {
                      text: [],
                    },
                  },
                  false,
                  false
                );
              }, 300);
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Telegram Traffic",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#14151C", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
          borderColor: "#40475D",
        },
        xaxis: {
          categories: [],
          max: 10,
        },
      },

      seriesGithub: [
        {
          name: "Developer Activity",
          data: [],
        },
      ],
      chartOptionsGithub: {
        theme: {
          mode: "dark",
        },
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          background: "rgba(0, 0, 0, 0)",
          foreColor: "#fff",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 1000,
            },
          },
          dropShadow: {
            enabled: true,
            opacity: 0.3,
            blur: 5,
            left: -7,
            top: 22,
          },
          events: {
            animationEnd: function (chartCtx) {
              const newData1 = chartCtx.w.config.series[0].data.slice();
              newData1.shift();
              window.setTimeout(function () {
                chartCtx.updateOptions(
                  {
                    series: [
                      {
                        data: newData1,
                      },
                    ],
                    subtitle: {
                      text: [],
                    },
                  },
                  false,
                  false
                );
              }, 300);
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Github Activity",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#14151C", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
          borderColor: "#40475D",
        },
        xaxis: {
          categories: [],
          max: 10,
        },
      },
      ipAddr: null,
      setIsUsingVPN: null,
      setIsUsingProxy: null,
      setIsUsingTor: null,
      setIsUsingRelay: null,
      location: null,
      gettingLocation: false,
      errorStr: null,
      isTrue: 0,
      captcha: "0x",
      adsDapps: [],
      imagesUrl: process.env.VUE_APP_API,
    };
  },
  mounted() {
    this.getDappsDetail();
    this.$nextTick(() => {
      // console.log(this.$refs.swiperTop.$swiper); // initialize swiper controller
      // console.log(this.$refs.swiperThumbs.$swiper);  // initialize swiper controller
      const swiperTop = this.$refs.swiperTop.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs.$swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });

    axios.get("https://iptools-4.top10vpn.com/ip/?genToken=1").then((res) => {
      // console.log("Detect ip: ", res.data.ip);
      this.ipAddr = res.data.ip;

      axios
        .get(
          "https://vpnapi.io/api/" +
            res.data.ip +
            "?key=a872950c6467409f8d2c11d1b523239c"
        )
        .then((res) => {
          if (res.data.security?.vpn === false) {
            this.setIsUsingVPN = false;
          } else {
            this.setIsUsingVPN = true;
          }
          if (res.data.security?.proxy === false) {
            this.setIsUsingProxy = false;
          } else {
            this.setIsUsingProxy = true;
          }
          if (res.data.security?.tor === false) {
            this.setIsUsingTor = false;
          } else {
            this.setIsUsingTor = true;
          }
          if (res.data.security?.relay === false) {
            this.setIsUsingRelay = false;
          } else {
            this.setIsUsingRelay = true;
          }
        });
    });
    this.recaptcha();
    this.getAds();
  },
  methods: {
    getDappsDetail() {
      let slugs = this.$props.slug;
      axios
        .get(process.env.VUE_APP_API + "api/product/details/" + slugs)
        .then((response) => {
          this.dappsDetail = response.data.data[0];
          let cate = response.data.data[0].categorys[0]?.slug;
          axios
            .get(process.env.VUE_APP_API + "api/product/related/" + cate)
            .then((res) => {
              this.relatedDapps = res.data.data.products;
            });
          this.getDataSocial();
        });
    },
    openLightboxOnSlide: function (number) {
      this.slide = number;
      this.toggler = !this.toggler;
    },
    getDataSocial() {
      let tg = this.dappsDetail.telegram_url?.endsWith("/")
        ? this.dappsDetail.telegram_url.slice(0, -1)
        : this.dappsDetail.telegram_url;
      let twt = this.dappsDetail.twitter_url?.endsWith("/")
        ? this.dappsDetail.twitter_url.slice(0, -1)
        : this.dappsDetail.twitter_url;
      let github = this.dappsDetail.github_url?.endsWith("/")
        ? this.dappsDetail.github_url.slice(0, -1)
        : this.dappsDetail.github_url;

      axios
        .get("https://api-social-data.dinodapps.com/socialmedia", {
          params: {
            telegram: tg,
            twitter: twt,
            github: github,
          },
        })
        .then((res) => {
          // console.log(res.status)
          if (!res.data.status) {
            this.is_github_active = res.data.isgithub_active;
            this.is_twitter_active = res.data.istwitter_active;
            this.is_telegram_active = res.data.istelegram_active;
            this.$refs.twitters.updateSeries([
              {
                name: "Followers",
                data: res.data.dbtwitter?.followers,
              },
            ]);

            this.$refs.twitters.updateOptions({
              xaxis: {
                categories: res.data.dbtwitter?.timestamp,
              },
            });

            this.$refs.telegrams.updateSeries([
              {
                name: "Members",
                data: res.data.dbtelegram?.members,
              },
            ]);

            this.$refs.telegrams.updateOptions({
              xaxis: {
                categories: res.data.dbtelegram?.timestamp,
              },
            });

            this.$refs.githubs.updateSeries([
              {
                name: "Activity",
                data: res.data.dbgithub?.commits,
              },
            ]);

            this.$refs.githubs.updateOptions({
              xaxis: {
                categories: res.data.dbgithub?.timestamp,
              },
            });
          } else {
            axios
              .post("https://api-social-data.dinodapps.com/socialmedia", {
                params: {
                  telegram: tg,
                  twitter: twt,
                  github: github,
                },
              })
              .then((res) => {
                this.is_twitter_active = res.data.istwitter_active;
                this.is_telegram_active = res.data.istelegram_active;
                this.$refs.twitters.updateSeries([
                  {
                    name: "Followers",
                    data: res.data.dbtwitter?.followers,
                  },
                ]);

                this.$refs.twitters.updateOptions({
                  xaxis: {
                    categories: res.data.dbtwitter?.timestamp,
                  },
                });

                this.$refs.telegrams.updateSeries([
                  {
                    name: "Members",
                    data: res.data.dbtelegram?.members,
                  },
                ]);

                this.$refs.telegrams.updateOptions({
                  xaxis: {
                    categories: res.data.dbtelegram?.timestamp,
                  },
                });

                this.$refs.githubs.updateSeries([
                  {
                    name: "Activity",
                    data: res.data.dbgithub?.commits,
                  },
                ]);

                this.$refs.githubs.updateOptions({
                  xaxis: {
                    categories: res.data.dbgithub?.timestamp,
                  },
                });
              });
          }
        });
    },
    async handleCount() {
      await axios
        .post("https://api-shill.dinodapps.com/api/count/dapps", {
          slugPool: this.$props.pools,
          referral: this.$props.queries,
          ip: this.ipAddr,
          key: this.captcha,
        })
        .then((res) => {
          console.log(res);
          window.open(this.dappsDetail.dapps_url);
        })
        .catch((err) => {
          console.log(err);
          window.open(this.dappsDetail.dapps_url);
        });
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      // await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("login");
      console.log(token);
      // Do stuff with the received token.
    },
    onVerify: function (response) {
      console.log("Verify: " + response);
      this.resetRecaptcha();
      this.isTrue = 1;
      let newKey = response;
      this.captcha = response;

      if (this.$props.queries !== undefined) {
        axios
          .get("https://iptools-4.top10vpn.com/ip/?genToken=1")
          .then((res) => {
            axios.post("https://api-shill.dinodapps.com/api/count/page", {
              slugPool: this.$props.pools,
              referral: this.$props.queries,
              ip: res.data.ip,
              key: newKey,
            });
          });
      }
    },
    onExpired: function () {
      console.log("Expired");
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    getAds() {
      axios
        .get(process.env.VUE_APP_API + "api/ads/type/ads-dappspage")
        .then((res) => {
          this.adsDapps = res.data.data[0];
        });
    },
  },
  computed: {
    gets() {
      var contracts = [];
      // console.log(this.dappsDetail.token_contract.length)
      if (
        this.dappsDetail.token_contract != null &&
        this.dappsDetail.token_contract != "0x"
      ) {
        for (var i = 0; i < this.dappsDetail.token_contract.length; i++) {
          // console.log("length", this.dappsDetail.token_contract[i].contracts.length)
          for (
            var k = 0;
            k < this.dappsDetail.token_contract[i].contracts.length;
            k++
          ) {
            let obj1 = [];
            obj1 = {
              contract:
                this.dappsDetail.token_contract[i].contracts[k].contract,
              is_token_contract:
                this.dappsDetail.token_contract[i].contracts[k]
                  .is_token_contract,
              network: this.dappsDetail.token_contract[i].network,
            };
            contracts.push(obj1);
            // console.log(this.dappsDetail.token_contract[i].contracts[k]);
          }
        }
      }
      return contracts;
    },
  },
  created() {
    this.$mixpanel.track("Open Detail Dapps " + this.$props.slug);

    if (!("geolocation" in navigator)) {
      this.errorStr = "Geolocation is not available.";
      return;
    }

    this.gettingLocation = true;
    // get position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.gettingLocation = false;
        this.location = pos;
        console.log(pos);
      },
      (err) => {
        this.gettingLocation = false;
        this.errorStr = err.message;
      }
    );
  },
};
</script>

<style>
.tooltip {
  display: none;
  opacity: 0;
  transition: opacity 0.15s;
  pointer-events: none;
  padding: 4px;
  z-index: 10000;
}

.tooltip .tooltip-content {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip.tooltip-open-transitionend {
  display: block;
}

.tooltip.tooltip-after-open {
  opacity: 1;
}

.custom {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.custom::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.custom-banner {
  height: auto !important;
  width: 300px;
  min-width: 10px !important;
}

.btn-social {
  width: 38px;
  height: 38px;
  border-radius: 10px;
  border: 1px solid #77818f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-dapps {
  width: 150px;
  background-color: #fff !important;
}
.swiper.gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
}
.swiper.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.swiper.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}
</style>
